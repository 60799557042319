.login {
    display: flex;
    justify-content: center;
   align-items: center;
   height: 100vh;
   width: 100%;
}

.login .login-main-box{
    height: 380px;
    width: 450px;
    box-shadow: 0px 2px 8px 1px gray;
    border-radius: 10px;

}

.login-header-box{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    font-size: large;
    font-weight: 500;
    background-color: rgb(214, 220, 225);
}


.login-textfield-box{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 350px;
    gap: 10px;
}
.login-textfield-box .forgot-password-link{
    text-align: end;
    margin-top: 7px;
    font-size: small;
    font-family:Arial, Helvetica, sans-serif;
}
.register-textfield-box{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 400px;
    gap: 25px;
}
.register-textfield-box .login-textfield{
    width: 350px;
}

.register-textfield-box .login-textfield fieldset{
    height: 45px;
}


.login-textfield-box .login-textfield{
    width: 350px;
}

.login-textfield-box .login-textfield fieldset{
    height: 45px;
}

.login-textfield-box  .login-btn{
    width: 350px;
    background-color: rgb(66, 66, 250);
    color: #ffff;
    height: 40px;
}

.login-textfield-box  .login-btn:hover{
    width: 350px;
    height: 40px;
    background-color: rgb(66, 66, 250);
    color: #ffff;
}


.register-textfield-box  .login-btn{
    width: 350px;
    background-color: rgb(66, 66, 250);
    color: #ffff;
    height: 40px;
}

.register-textfield-box  .login-btn:hover{
    width: 350px;
    height: 40px;
    background-color: rgb(66, 66, 250);
    color: #ffff;
}

.login-footer-box .login-footer-text{
    font-size: medium;
    font-weight: 500;
    text-align: center;
    font-family:Arial, Helvetica, sans-serif;
}