.profile-img-container::-webkit-scrollbar {
    /* display: none; */
    width: 7px;
  }

  .profile-img-container::-webkit-scrollbar-track {
    background: #e2e0e0;
    box-shadow: inset 0 0 5px rgb(215, 213, 213);
    border-radius: 5px;
  }

  .profile-img-container::-webkit-scrollbar-thumb {
    height: 20px; 
    background: #c1c1c1; 
    border-radius: 5px;
  }
  
   
.portfolio-img-modal-box .portfolio-img-modal{
    /* width: 100%; */
    width: 240px;
    height: 160px;
    margin-right: 10px;
    border-radius: 10px;
    overflow: auto;
}


.portfolio-img-modal-box {
    position: relative;
}


.portfolio-img-modal-box .portflio-img-remove-icon{
    position: absolute;
    cursor: pointer;
    top: -8%;
    right: -6%;
    background-color: #db2626;
    width: 26px;
    height: 26px;
    border-radius: 50px;
    color: #fff;
    padding: 4px;
}


.portfolio-img-main-box {
    position: relative;
}


.portfolio-img-main-box .portflio-img-remove-icon{
    position: absolute;
    cursor: pointer;
    top: -5%;
    right: 13%;
    background-color: #db2626;
    width: 23px;
    height: 23px;
    border-radius: 50px;
    color: #fff;
    padding: 3px;
}


.profile-img-container {
    height: 455px;
    overflow: auto;
    padding: 30px;
    margin-top: 15px;
    box-shadow: 5px 3px 4px 6px #f0ebeb;
}

.portfolio-img-main-box .portfolio-profile-img {
    height: 165px;
    width: 240px;
    border-radius: 10px;
    margin: 0px 0px 20px 20px;
}

.image-text-box-modal .image-text-modal{
    font-size: 16px;
    font-weight: 600;
}

.upload-img-box .upload-img-icon{
    margin-left: 55px;
    cursor: pointer;
}