
.heading-box{
  display: flex;
  justify-content: space-between;
  margin: 0px 10px;
  align-items: center;
}

.heading-box .heading-text{
  font-size: 18px;
  font-weight: 600;
  font-family: Arial, Helvetica, sans-serif;
}


.heading-add-box .add-button{
  background: #7878aa;
  color: #ffff;
  border-radius: 10px;
  text-transform: capitalize;
  width: 120px;
}
.heading-add-box .add-button:hover{
  background: #7878aa;
  color: #ffff;
  border-radius: 10px;
  text-transform: capitalize;
}

.heading-box .heading-add-box{
  display: flex;
  gap: 8px;
}