* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.main-add-edit {
  padding: 23px;
}


.main-input{
    margin-top: 10px;
}

.main-input .custom-input input {
  padding: 10px 12px;
}


.main-content{
    margin-top: 25px;
}