*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.main-role{
    padding: 23px;
}

.top-role{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.right-top-role .btn-primary{
  width: 120px;
  height: 40px;  
  text-transform: capitalize;
  background-color: rgb(127, 127, 202);
}

.right-top-role .btn-primary:hover{
  background-color: rgb(127, 127, 202);
}

.main-content{
    margin-top: 30px;
}