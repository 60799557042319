* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.main-table {
  padding: 23px;
}

.top-table {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.right-top-table .btn-primary {
  /* width: 120px; */
  height: 40px;
  text-transform: capitalize;
  background-color: rgb(127, 127, 202);
}

.right-top-table .btn-primary:hover {
  background-color: rgb(127, 127, 202);
}

.main-content {
  margin-top: 30px;
}

.main-add-edit {
  padding: 23px;
}

.main-input {
  margin-top: 10px;
}

.main-input .custom-input input {
  padding: 10px 12px;
}

.left-heading-table h5 {
  text-transform: capitalize;
}

.error {
  color: red;
}

.menu-active{
  background-color: #58589b;
  color: #fff;
  cursor: pointer;
  list-style: none;
  padding: 20px 10px;
  margin-top: 10px;
  border-radius: 5px;
}

.loader-main{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 999999;
  display: flex;
  justify-content: center;
  align-items: center;
}
