* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.admin-main-content-list {
  padding: 20px 24px;
  height: calc(100vh - 64px);
  background: #f9f9f9;
  overflow: auto;
}

.footer-bg-content{
  display: none;
}

.admin-common-title-details .admin-heade-bg-title {
  background: rgba(182, 153, 189, 0.4588235294);
  padding: 10px 90px 10px 20px;
  border-radius: 7px;
  margin-bottom: 15px;
  position: relative;
}

.admin-main-content-list
  .admin-common-title-details
  .admin-heade-bg-title
  .admin-flex-common {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  margin: 0;
}

.admin-main-content-list
  .admin-common-title-details
  .admin-heade-bg-title
  .admin-flex-common
  .admin-common-title {
  font-size: 20px;
  font-weight: 700;
  color: #000000;
}

.user-profile-form {
  margin: auto;
}
.profile-max-user-details {
  margin: auto;
}

.profile-max-user-details .profile-edit-bg {
  border-radius: 8px;
}

.profile-max-user-details .profile-edit-bg .profile-view-listing {
  background: #e2e5ed;
  padding: 15px 30px 25px 30px;
  border-radius: 8px 8px 0px 0px;
  border-bottom: 1px solid rgba(197, 197, 197, 0.5882352941);
}

.profile-max-user-details
  .profile-edit-bg
  .profile-view-listing
  .profile-view-flex {
  display: flex;
  align-items: center;
  gap: 15px;
}

.profile-max-user-details
  .profile-edit-bg
  .profile-view-listing
  .profile-view-flex
  .profile-image-view {
  display: flex;
  position: relative;
}

.profile-max-user-details
  .profile-edit-bg
  .profile-view-listing
  .profile-view-flex
  .profile-image-view
  .profile-viewer {
  height: 80px;
  width: 80px;
  object-fit: cover;
  border-radius: 50%;
  border: 3px solid #551067;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.profile-max-user-details
  .profile-edit-bg
  .profile-view-listing
  .profile-view-flex
  .profile-image-view
  .profile-btn-label {
  background: #551067;
  min-width: unset;
  position: absolute;
  padding: 5px;
  height: 27px;
  width: 28px;
  border-radius: 50%;
  bottom: -12px;
  left: 0;
  right: 0;
  margin: auto;
}

.profile-max-user-details
  .profile-edit-bg
  .profile-view-listing
  .profile-view-flex
  .profile-name-details
  .profile-user-name {
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 2px;
}

.profile-max-user-details
  .profile-edit-bg
  .profile-view-listing
  .profile-view-flex
  .profile-name-details
  .profile-user-email {
  font-size: 14px;
  line-height: 20px;
  color: #495057;
}

.profile-max-user-details .profile-edit-bg .profile-edit-padding {
  background: #ffffff;
  padding: 15px 30px 30px 30px;
  border-radius: 0px 0px 8px 8px;
}

.common-input-field .common-label-details {
  margin-top: 0px;
  font-size: 14px;
  margin-bottom: 5px;
  color: #000000;
}

.css-wb57ya-MuiFormControl-root-MuiTextField-root {
  width: 100%;
}

.common-input-field .input-common-field .common-textfield-content input {
  padding: 9px 15px;
  background: #ffffff;
  border-radius: 8px;
  font-size: 14px;
  color: #2a3547;
  line-height: 18px;
}

.common-input-field .input-common-field .common-textfield-content fieldset {
  border-color: #ceced5;
  border-width: 1px;
  border-radius: 8px;
}

.common-btn-details .end-justify-content {
  justify-content: end;
}

.common-btn-details .button-secondary {
  background: #e2e5ed;
  color: #000000;
  border-radius: 7px;
  font-size: 14px;
  text-transform: capitalize;
  line-height: 18px;
  padding: 10px 20px;
}

.common-btn-details .button-secondary:hover {
  background: #e2e5ed;
}

.common-btn-details .button-primary {
  padding: 10px 20px;
  background: #551067;
  color: #ffffff;
  border-radius: 7px;
  font-size: 14px;
  text-transform: capitalize;
  line-height: 18px;
}

.common-btn-details .button-primary:hover {
  background: #551067;
}


.textfield-drop-down-city .MuiSelect-select {
  padding: 7px;
  padding-left: 15px;
  text-align: start;
  background-color: #fff;
  font-size: 14px;
  border-radius: 5px;
  border: 1px solid #ddd;
}