.slider-home-user .hero-slick-details .hero-slick-key .hero-slider-img {
  position: relative;
}

.footer-bg-content{
  display: block;
}

.slider-home-user
  .hero-slick-details
  .hero-slick-key
  .hero-slider-img
  .hero-slider-banner {
  object-fit: cover;
  object-position: top;
  width: 100%;
  height: 100vh;
}

.slider-home-user
  .hero-slick-details
  .hero-slick-key
  .hero-slider-img
  .overlay-hero-content {
  background-color: rgb(0 0 0 / 39%);
  position: absolute;
  width: 100%;
  height: 100vh;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 9;
}

.hero-slick-details .swiper-bg-content {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  height: 770px;
  position: relative;
 
}

.slider-home-user
  .hero-slick-details
  .swiper-bg-content
  .swiper-detail-content {
  max-width: 650px;
}

.swiper-detail-content .swiper-heading {
  color: white;
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 20px;
  animation-name: fadeInBottom;
  animation-duration: 0.3s;
}

.swiper-detail-content .swiper-heading-inner {
  text-transform: uppercase;
  margin-bottom: 20px;
  color: #ffff;
  font-weight: 700;
}

.swiper-detail-content .swiper-heading-description {
  font-size: 15px;
  line-height: 22px;
  margin-bottom: 20px;
  color: #fff;
}

.swiper-detail-content .swiper-btn-details {
  max-width: 170px;
}

.swiper-btn-details .btn-primary {
  font-size: 16px;
  line-height: 16px;
  font-weight: 500;
  width: 100%;
  padding: 15px 15px;
  text-transform: uppercase;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.common-auto-textarea {
  width: 100%;
  min-height: 100px;
  padding: 11px 15px;
  background: #ffffff;
  border-radius: 8px;
  font-size: 15px;
  line-height: 19px;
  border-color: #ceced5;
  resize: none;
  font-family: "Poppins", "sans-serif";
}

/* CSS to show navigation arrows on hover */
.slide-main-content:hover .swiper-button-prev,
.slide-main-content:hover .swiper-button-next {
  opacity: 1 !important;
}

/* contact us */

.contact-us-common {
  padding: 50px 0px;
}

.contact-us-common .contact-us-details-right .get-contact-title {
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 30px;
  color: #2a3547;
}

.contact-us-common .contact-us-details-right .get-dercription {
  font-size: 17px;
  margin-bottom: 30px;
  color: #2a3547;
  line-height: 24px;
}

.contact-us-common .contact-details-show .contact-details-list {
  display: flex;
  gap: 10px;
  font-size: 17px;
  color: #2a3547;
  line-height: 24px;
  margin-bottom: 15px;
}

.contact-us-common .contact-details-show .contact-details-list span {
  font-weight: 700;
  min-width: 81px;
  display: block;
}

.contact-us-common .contact-form-list {
  margin-top: 50px;
}

.contact-us-common .contact-form-list .btn-contact-main {
  max-width: 170px;
}

.contact-us-common .contact-form-list .btn-contact-main .btn-primary {
  font-size: 16px;
  line-height: 16px;
  font-weight: 500;
  width: 100%;
  padding: 15px 15px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.contact-us-common .map-contact-details .iframe-contact-map {
  width: 100%;
  min-height: 708px;
  object-fit: cover;
}