*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.admin-master{
    margin-top: 20px;
    background-color: #b0b0e9;
    border-radius: 5px;
    padding: 10px;
    overflow: auto;
}

.admin-form{
    width: 100%;
}

.admin-input{
    margin-top: 10px;
}

.admin-input .MuiTextField-root{
    width: 100%;
}

.right-top-table-custom{
    display: flex;
    gap: 8px;
}