* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.main-role {
  padding: 23px;
}

.top-role {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.right-top-role .btn-primary {
  width: 140px;
  height: 40px;
  text-transform: capitalize;
  background-color: rgb(127, 127, 202);
}

.right-top-role .btn-primary:hover {
  background-color: rgb(127, 127, 202);
}

.right-top-role {
  display: flex;
  gap: 8px;
}

.main-content {
  margin-top: 30px;
}
.table-main {
  margin-top: 20px;
  /* border: solid rgb(224, 224, 224); */
  overflow: auto;
  height: 400px;
}

.table-main .table-main-column {
  font-weight: bold;
}

/* .cus-main-modal .cus-modal {
  width: 450px;
  position: fixed;
  top: "50%";
  left: "50%";
  transform: "translate(-50%, -50%)";
  background-color: #fff;
  border-radius: 15px;
  outline: none;
}

.cus-modal .modal-heading-text {
  font-size: 30px;
  text-align: center;
  font-weight: bold;
}

.cus-modal .modal-text {
  margin: auto;
  margin-top: 20px;
  max-width: 300px;
  font-size: 16px;
  text-align: center;
  color: gray;
}

.first-cancel-btn button {
  width: 120px;
  height: 43px;
  color: #fff;
  font-size: 14px;
  border-radius: 20px;
  background-color: rgba(20, 19, 19, 0.719);
}

.second-delete-btn button {
  width: 120px;
  height: 43px;
  color: #fff;
  font-size: 14px;
  border-radius: 20px;
  margin-left: 15px;
  background-color: #10283d;
}

.first-cancel-btn button:hover {
  background-color: rgba(20, 19, 19, 0.719);
}

.second-delete-btn button:hover {
  background-color: #10283d;
}

.main-button-modal {
  display: flex;
  align-items: center;
  justify-content: center;
} */

.cus-main-modal .cus-modal {
  width: 500px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 15px;
  outline: none;
  padding: 50px;
}

.cus-modal .modal-heading-text {
  font-size: 30px;
  text-align: center;
  font-weight: bold;
}

.cus-modal .modal-text {
  margin: auto;
  margin-top: 20px;
  max-width: 300px;
  font-size: 16px;
  text-align: center;
  color: gray;
}

.first-cancel-btn button {
  width: 120px;
  height: 43px;
  color: #fff;
  font-size: 14px;
  border-radius: 20px;
  background-color: rgba(20, 19, 19, 0.719);
}

.second-delete-btn button {
  width: 120px;
  height: 43px;
  color: #fff;
  font-size: 14px;
  border-radius: 20px;
  margin-left: 15px;
  background-color: rgba(20, 19, 19, 0.719);
  /* background-color: #10283d;  */
}

.first-cancel-btn button:hover {
  background-color: #10283d;
}

.second-delete-btn button:hover {
  background-color: #10283d;
}

.main-button-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.first-cancel-btn {
  margin-right: 20px;
}

.cus-modal .add-category-data {
  margin: 0px;
}

.right-top-table{
  display: flex;
  gap: 8px;
}