
.prof-main-modal .question-modal-main{
    width: 580px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border-radius: 15px;
    outline: none;
    padding: 30px 55px; 
    max-height: 880px;
    overflow: auto;   
   }
   
   .prof-main-modal .question-modal-main::-webkit-scrollbar {
    display: none;
  }

  .question-modal-middle-main-box .question-modal-middle-box{
    /* display: flex; */
    /* justify-content: space-between; */
    margin: 20px;
  }
.question-modal-middle-box .question-rating{
  margin: 5px 0px 5px 15px;
}
 
  .table-body .rating-btn{
    color: #faaf00;
    text-transform: capitalize;

  }

  .table-body .rating-btn:hover{
    color: #faaf00;
    text-transform: capitalize;
 
  }

  .question-modal-middle-box .question-text{
    width: 450px;
    font-size: 15px;
    font-weight: 500;
    line-height: 18px;
  }

  .rating-text{
    cursor: pointer;
  }