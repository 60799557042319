.cus-main-modal .cus-modal {
  width: 500px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 15px;
  outline: none;
  padding: 50px;
}

.cus-modal .modal-heading-text {
  font-size: 30px;
  text-align: center;
  font-weight: bold;
}

.cus-modal .modal-text {
  margin: auto;
  margin-top: 20px;
  max-width: 300px;
  font-size: 16px;
  text-align: center;
  color: gray;
}

.first-cancel-btn button {
  width: 120px;
  height: 43px;
  color: #fff;
  font-size: 14px;
  border-radius: 20px;
  background-color: rgba(20, 19, 19, 0.719);
}

.second-delete-btn button {
  width: 120px;
  height: 43px;
  color: #fff;
  font-size: 14px;
  border-radius: 20px;
  margin-left: 15px;
  background-color: rgba(20, 19, 19, 0.719);
  /* background-color: #10283d; */
}

.first-cancel-btn button:hover {
  background-color: #10283d;
}

.second-delete-btn button:hover {
  background-color: #10283d;
}

.main-button-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.my-profile {
  overflow: auto;
  width: 100%;
  gap: 20px;
  /* width: 100%; */
}

.profile-section {
  text-align: center;
}

.my-profile .profile-image img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.loader-button {
  color: #fff !important;
  background-color: #5d5dd9 !important;
  cursor: pointer !important;
}

.profile-content-section {
  overflow: auto;
  /* width: 100%; */
  display: flex;
  gap: 30px;
}

.profile-content-section .profile-content-title {
  text-transform: capitalize;
  font-size: 12px;
  font-weight: 500;
  /* font-family: 'Courier New', Courier, monospace; */
}
.profile-content-title .profile-data-list {
  font-size: 14px;
  font-weight: 700;
}

.view-profile-data .profile-content-data {
  font-size: 14px;
}

.view-profile-data {
  display: flex;
  gap: 30px;
  margin-bottom: 5px;
}

.custom-data-profile {
  min-width: 105px;
  max-width: 110px;
}

.custom-item img {
  /* border-radius: 8px; */
  width: 100%;
  height: 220px;
  object-fit: cover;
}

.my-profile-content {
  height: calc(100vh - 176px);
  overflow: auto;
  padding: 0 !important;
}

.my-profile-content::-webkit-scrollbar {
  width: 5px;
}

.my-profile-content::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.my-profile-content::-webkit-scrollbar-thumb {
  background: #c2c1c1;
}

.my-profile-content::-webkit-scrollbar-thumb:hover {
  background: #818181;
}

.my-profile-content .custom-container {
  padding: 15px;
  border: transparent;
}

.top-border-line {
  padding: 10px;
  border-top: 1px solid #0000001f;
}

.top-border-line .profile-data-lists {
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 700;
}

.custom-table-container table tr th {
  background-color: rgb(179 179 205);
  font-size: 14px;
  font-weight: 700;
  padding: 8px 10px;
  /* border-radius:; */
}

.custom-table-container table tr th:nth-child(1) {
  width: 40%;
  border-right: 1px solid #0000001f;
  border-bottom: 1px solid #0000001f;
}

.custom-table-container table tr th:nth-child(2) {
  border-bottom: 1px solid #0000001f;
}

.custom-table-container table tr td:nth-child(2),
.custom-table-container table tr td:nth-child(1) {
  border: 1px solid #0000001f;
  padding: 8px 10px;
}

.custom-table-container table {
  border: 1px solid #0000001f;
  border-radius: 8px;
}

.professional-view-profile {
  display: flex;
}


.booking-list-view{
  max-height: 436px;
}