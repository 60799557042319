.availbility-main-box .availbility-text-box{
    width: 550px;
    font-size: 16px;
    font-weight: 600;
    margin-top: 16px;
    margin-left: 5px;
    font-family: Arial, Helvetica, sans-serif;
}

.availbility-main-box{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    
}

.calender-tag .rmdp-arrow-container{
    margin: 0 3px;
    height: 16px;
    width: 16px;
}

.calender-tag .rmdp-calendar {
    height: 455px;
    width: 700px;
    background: rgb(237 237 249);
}

.calender-tag .rmdp-day-picker {
    display:block;
}

.calender-tag .rmdp-week .rmdp-day, .calender-tag .rmdp-week .rmdp-week-day {
    color: #000;
    cursor: pointer;
    height: 55px;
    position: relative;
    width: 100px;
}
.calender-tag .rmdp-week .rmdp-disabled{
    color: gray;
}
.calender-tag .rmdp-day.rmdp-selected span:not(.highlight){
    background-color: rgb(120, 120, 170);;
}
/* .rmdp-day span:hover{
    background-color: rgb(120, 120, 170) !important;
} */
.calender-tag .rmdp-panel-body li, .rmdp-panel-body li.bg-blue {
    background-color:  rgb(120, 120, 170);
}
.calender-tag .rmdp-panel-body li .b-deselect {
    background-color:  rgb(120, 120, 170);

}
.calender-tag .rmdp-day span {
    border-radius: 50%;
    bottom: 7px;
    font-size: 16px;
    left: 30px;
    position: absolute;
    right: 27px;
    top: 15px;
    font-weight: 400;
    width: 33px;
}
.calender-tag .rmdp-panel-body li .b-date {
    font-size: 16px;
}

.calender-tag .rmdp-week-day{
    font-size: 13px;
    font-weight: 600;
}

.calender-tag .rmdp-header-values{
    font-size: 16px;
    font-weight: 600;
}

.availability-btn-box{
display: flex;
justify-content: space-between;
margin: 10px 0px;   

}

.availability-btn-box .availability-btn{
    background-color: rgb(120, 120, 170);;
    color: #fff;
    text-transform: capitalize;
    width: 55px;
    height: 26px;
    border-radius: 10px;
    margin-top: 14px;
    font-size: 12px;
}

.availability-btn-box .availability-btn:hover{
    background-color: rgb(120, 120, 170);;
    color: black;

}

.calender-tag .rmdp-panel {
    min-width: 190px;
    background: rgb(237 237 249);
}
.calender-tag .rmdp-panel-body li .b-deselect span {
width: 25px;
}
.calender-tag .rmdp-panel-body li button {
    cursor: default !important;
}

.calender-tag .rmdp-panel-body li .b-deselect span {
    cursor: pointer;
}
.calender-tag .rmdp-panel-header {
   font-weight: 600;
}