.main-table .terms-middle-box{
    margin: 20px;
}
.terms-middle-box .term-title-box{
    margin-bottom: 15px;
}

.term-title-box .term-title-text{
    margin: 0px 0px 5px 5px;
    font-size: 15px;
    font-weight: 500;
    font-family: Arial, Helvetica, sans-serif;

}

.terms-description-box .ck-content{
    height: 250px;
}

.terms-description-box .decription-text{
    margin: 0px 0px 5px 5px;
    font-size: 15px;
    font-weight: 500;
    font-family: Arial, Helvetica, sans-serif;
}

.terms-middle-box  .terms-btn-box{
    display: flex;
    justify-content: center;
    align-items: center;
}

.terms-btn-box .terms-btn{
    background-color: rgb(120, 120, 170);
    color: #ffff;
    cursor: pointer;
    margin-top: 12px;
    text-transform: capitalize;
    border-radius: 8px;
}
.terms-btn-box .terms-btn:hover{
    background-color: rgb(120, 120, 170);

}