
.heading-box{
  display: flex;
  justify-content: space-between;
  margin: 0px 10px;
  align-items: center;
}

.heading-box .heading-text{
  font-size: 18px;
  font-weight: 600;
  font-family: Arial, Helvetica, sans-serif;
}

/* .heading-box .heading-add-box{
   width: 55vh;
    display: flex;
    justify-content: space-between;
} */

.heading-add-box .add-button{
  background: #7878aa;
  color: #ffff;
  border-radius: 10px;
  text-transform: capitalize;
  width: 120px;
}

.heading-search-add {
  display: flex;
  gap: 8px;
}

.addAdmin_activity_form{
  width: 70vw;
  display: flex;
  justify-content: center;
  background-color: #eeeeee;
  border: 1px solid black;
  margin: 0px 50px;
  padding: 40px;
  border-radius: 8px;
}

.header-btn-box{
  margin:30px 70px 20px 50px;
  display: flex;
  justify-content: space-between;
}

.header-btn-box .header-btn-back{
  background: #7878aa;
  color: #ffff;
  border-radius: 8px;
  text-transform: capitalize;
  width: 80px;
  height: 35px;
}
.header-btn-box .header-btn-back:hover{
  background: #7878aa;

}
.footer-btn-box {
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-btn-box .btn-update{
  background: #7878aa;
  color: #ffff;
  border-radius: 10px;
  text-transform: capitalize;
  width: 100px;
  margin-top: 20px;
}
.footer-btn-box .btn-update:hover{
  background: #7878aa;

}
.footer-btn-box .btn-update-disable{
  background: #7878aa;
  color: lightgray;
  border-radius: 10px;
  text-transform: capitalize;
  width: 100px;
  margin-top: 20px;
}
.footer-btn-box .btn-update-disable:hover{
  background: #7878aa;
  color: lightgray;

}

.btn-add-service-box .field-btn-add-service{
  background: #7878aa;
  color: #ffff;
  border-radius: 7px;
  text-transform: capitalize;
  width: 100px;
  margin-top: 10px;
  height: 26px;
  font-size: 14px;
}

.btn-add-service-box .field-btn-add-service:hover{
  background: #7878aa;
  color: #ffff;
  
}


.btn-add-service-box .field-btn-add-disable{
  background: #7878aa;
  color: lightgray;
  border-radius: 7px;
  text-transform: capitalize;
  width: 100px;
  margin-top: 10px;
  height: 26px;
  font-size: 14px;
}

.btn-add-service-box .field-btn-add-disable:hover{
  background: #7878aa;
  color: lightgray;

}


.btn-add-remove-box .field-btn-remove-service{
  background: #7878aa;
  color: #ffff;
  border-radius: 7px;
  text-transform: capitalize;
  width: 70px;
  margin-top: 10px;
  height: 24px;
  font-size: 14px;
}

.btn-add-remove-box .field-btn-remove-service:hover{
  background: #7878aa;
  color: #ffff;

}


.service-icon-box .delete-icon{
  color: red;
  cursor: pointer;
  margin-left: 5px;
}

.services_className{
  max-height: 325px;
  overflow: auto;
  padding-right: 7px;
}


.services_className::-webkit-scrollbar {
  /* display: none; */
  width: 7px;
}

.services_className::-webkit-scrollbar-track {
  background: #e2e0e0;
  box-shadow: inset 0 0 5px rgb(215, 213, 213);
  border-radius: 5px;
}

.services_className::-webkit-scrollbar-thumb {
  height: 20px;
  background: #c1c1c1;
  border-radius: 5px;
}

.search-filter-heading input {
  padding: 7px 14px;
}
