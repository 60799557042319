

.table_main_div{
    margin-top: 10px;
    border: 2px;
    border-radius: 20px;
}

.table_main_div, .table_tag, tr td{
    border-bottom: none !important;
}

.icons-container {
    display: flex;
    justify-content: space-between; /* or any other suitable value */
    width: 100px; /* Set the width according to your design */
  }


  
.icon {
  /* Optional: Add some styling to each individual icon */
  margin: 5px;
  cursor: pointer;
}

.button_tag{
display: flex;
justify-content: end;
}

.button_tag .main-input{
  margin-right: 10px;

}

.save_button{
  /* margin-left: 60%; */
  margin-top: 10px;
}


.cus-main-modal .cus-modal {
  width: 450px;
  position: absolute;
  top: "50%";
    left: "50%";
    transform: "translate(-50%, -50%)";
    background-color: #fff;
  border-radius: 15px;
  outline: none;
}

.cus-modal .modal-heading-text{
  font-size: 30px;
  text-align: center;
  font-weight: bold;
}

.cus-modal .modal-text{
  margin: auto;
  margin-top: 20px;
  max-width: 300px;
  font-size: 16px;
  text-align: center;
  color: gray;
}

.first-cancel-btn button{
  width: 120px;
  height: 43px;
  color: #fff;
  font-size: 14px;
  border-radius: 20px;
  background-color: rgba(20, 19, 19, 0.719);
}

.second-delete-btn button{
  width: 120px;
  height: 43px;
  color: #fff;
  font-size: 14px;
  border-radius: 20px;
  margin-left: 15px;
  background-color: #10283d;
}

.first-cancel-btn button:hover{
  background-color: rgba(20, 19, 19, 0.719);
}

.second-delete-btn button:hover{
  background-color: #10283d;

}

.main-button-modal{
  display: flex;
  align-items: center;
  justify-content: center;
}



.search_bar_box input{
  padding: 10px 0;
  }

  .search_bar_box .search_bar{
    margin-right: 5%;
  }

  .add_btn_box .add_btn {
    margin-top: 2%;
    border-radius: 2rem;
}

.add_icon button{
  margin-top: 10px;
}
/* .remove_icon button{
  margin-top: 10px;
  margin-left: 10px;
} */
.addAdmin_form{
  display: flex;
  justify-content: center;
}
.addAdmin_activity_zero_form{
  width: 75vw;
  display: flex;
  justify-content: center;
  background-color:#EEEEEE;
  border: 1px solid black;
  margin-top: 50px;
  padding: 40px;
  border-radius: 8px;
}


.skip_btn_box .skip_btn {
  margin-top: 7%;
  border-radius: 2rem;
}

.next_btn_box button{
  border-radius: 2rem;

}
.services_className_combine_div .services_className_combine{
  margin: 5px 0px 5px 0px;
  padding: 5px 0px 5px 0px;
}
.services_className_combine_div > .services_className_combine{
  padding: 10px;
  border-radius: 2rem;
  /* margin-bottom: -4px; */
}
.services_addservices_div > .services_className_combine{
  padding: 10px;
  border-radius: 2rem;
}
.pkg_grid .pkg_name_grid{
  margin: 15px;

}

.DES_BOX .DES{
  /* margin-bottom: 10%; */
  margin-left: 15px;
  background-color: #EEEEEE;
  padding-bottom: 0.5%;
}

.des1 .DES1{
  /* margin-bottom: 10%; */
  /* margin-left: 15px; */
  background-color: #EEEEEE;
  padding-bottom: 0.5%;
  width: 100%;
  height: 100PX;
}

.remove_btn_box .remove_btn{
  margin-top: 2%;
  margin-bottom: 0.8%;
  border-radius: 2rem;
}
.des_text{
  margin-left: 15px;
}

.services_text{
  margin-top: 15px;
}

.err{
  color: red;
}

