

.professional-list-main-box .professional-list-header{
    /* width: 68%; */
    height: 60px;
    background-color: #f3f1f1;
    display: flex;
    justify-content:space-between;
    padding: 0px 15px;
    align-items: center;
    gap: 10px;
   }
   
   .professional-list-main-box{
       margin: 30px 50px;
   }
   
   .professional-list-detail-box{
       /* margin: 50px 0px; */
       margin-top: 20px;
   }
   
   
   .professional-list-detail-box .professional-box{
       height: 250px;
    border: 2px solid #b2b2b2;
      border-radius: 10px;
      /* margin-left: 25px; */
      display: flex;
      gap: 30px;
      padding: 10px;
   }
   
   .prof-img-box .prof-img{
   
       width: 115px;
       height: 100px;
       border-radius: 5px;
       cursor: pointer;
   }
   
   .professional-detail-box .prof-name{
       font-size: 18px;
       font-weight: 600;
       font-family: Arial, Helvetica, sans-serif;
       cursor: pointer;
   }
   .professional-detail-box .prof-name:hover{
       color:rgb(247, 98, 98) ;
   }
   .professional-detail-box  .prof-city-box{
       display: flex;
       margin-top: 5px;
   
   }
   .professional-detail-box  .prof-city-icon{
       height: 20px;
       margin-top: 2px;
       color: royalblue;
       margin-right: 5px;
   }
   .professional-detail-box .prof-email-box{
       display: flex;
       margin-top: 5px;
   
   }
   .prof-email-box  .prof-email-icon{
       height: 20px;
       margin-top: 2px;
       color: gray;
       margin-right: 5px;
   }
   
   .prof-contact-box  .prof-contact-icon{
       height: 20px;
       margin-top: 2px;
       color: #00a600;
       margin-right: 5px;
   }
   
   .professional-detail-box .prof-contact-box{
       display: flex;
       margin-top: 5px;
   
   }
   
   .prof-time-box{
       display: flex;
       margin-top: 5px;
   }
   .prof-time-box .prof-time-icon{
           height: 20px;
       margin-top: 2px;
       /* color: #00a600; */
       margin-right: 5px;
   }
   
   .prof-time-box .prof-time-text{
       margin-top: 2px;
       color: #00a600;
       font-size: 18px;
       line-height: 19px;
   }
   
   .prof-service-box{
   display: flex;
   margin-top: 5px;
   
   }
   
   .prof-service-box .prof-service-text-heading{
       font-size: 15x;
       font-weight: 600;
       color: darkslategray;
       font-family: Arial, Helvetica, sans-serif;
       margin-right: 5px;
   }
   
   .prof-package-box .prof-package-text-heading{
       font-size: 16px;
       font-weight: 600;
       color: darkslategray;
       font-family: Arial, Helvetica, sans-serif;
   }
   
   .prof-package-box{
       display: flex;
       margin-top: 5px;
       font-family: Arial, Helvetica, sans-serif;
   
   
   }
   
   
   .category-dropdown{
       width: 220px;
   
   }
   
   .category-dropdown .MuiInputBase-input {
       padding: 8.5px 16px ;
   }
   
   .header-list-dropdown{
       width: 380px;   
    display: flex;
    justify-content: space-between;
   }
   
   
   
   
   .tap-heading-profile-box .tap-heading{
       width: 245px;
   }

   
   /* profession-detail-div */
   
   .profile-detail-main-box{
       margin: 20px 40px 0px 40px;
   }
   
   .profile-detail-main-box .prof-heading-box{
    height: 42vh;
    border: 0.5px solid #b2b2b2;
    border-radius: 15px;
    display: flex;
    padding: 30px;
    gap: 44px;
    width: 70%;
    margin: 40px 20px;
   }
   .prof-detail-heading-img-box .prof-detail-heading-img{
    width: 140px;
    height: 125px;
       border-radius: 12px;
   
   }
   
   .prof-detail-heading-right-box{
       /* width: 380px; */
       width: 80%;
   }
   
   .prof-detail-heading-right-box .name-text{
       font-size: 20px;
       font-weight: 600;
       font-family: Arial, Helvetica, sans-serif;
   
   }
   
   .prof-contact-box {
       display: flex;
       margin: 5px 0px;
   
   }
   .prof-email-box {
       display: flex;
       margin: 5px 0px;
   
   
   }
    .prof-city-box{
       display: flex;
       margin: 5px 0px;
   
    }
   
    .prof-city-box .prof-detail-city-icon{
       height: 20px;
       margin-top: 2px;
       color: royalblue;
       margin-right: 5px;
   }
   
   .tap-heading{
       width: 338px;
       font-family: Arial, Helvetica, sans-serif;
   }
   
    .tap-heading-profile-box .tap-heading{
       font-weight: 700 !important;
       text-transform: capitalize !important;
       color:black !important
   }
   .tap-heading-profile-box .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected{
       color: #1976d2!important;
       background-color: #ededed !important;
       text-decoration:none !important;
   }
   
   .prof-service-right-box{
    margin-top: 30px !important;
    /* width: 400px; */
    width: 40%;
   }
   
   .prof-package-box .text{
       margin-left: 5px;
   }
   .prof-time-box .text{
       margin-left: 5px;
   }

   .edit-icon-box .edit-icon{
color: rgb(111, 111, 170);
cursor: pointer;
   }


   .prof-main-modal .prof-main-box {
    width: 750px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border-radius: 15px;
    outline: none;
    padding: 30px 55px;

   }

   .prof-main-box .prof-heading-box{

 display: flex;
 justify-content: center;
 align-items: center;
 margin-bottom: 5px;
   }

   .prof-heading-box .prof-heading-text {
    font-size: 16px;
    font-weight: 600;
    font-family: Arial, Helvetica, sans-serif;
    text-align: center;
   }

   .prof-main-box .prof-edit-modal{
    display: flex ;
    gap: 25px;
   }

   .prof-edit-modal .prof-edit-input-box .input{
   margin-top: 25px;
   width: 300px;
   }

   .prof-edit-input-box .profile-label{
    font-size: 14px;
    font-weight: 500;
    font-family: Arial, Helvetica, sans-serif;
    margin-left:5px;
    }


    .table-action-icon-box .table-icon-switch{
     margin-right: 8px;
    }

    .table-action-icon-box .table-icon-edit{
     color: rgb(111, 111, 170);
     cursor: pointer;
     margin-right: 8px;
    height: 20px;
    }

    
    .service-icon-box .table-icon-edit{
        color: rgb(111, 111, 170);
        cursor: pointer;
        margin: 0px 5px;
       height: 20px;
       }
   

    .table-action-icon-box .table-icon-delete{
     color: red;
     cursor: pointer;
   height: 20px;

    }

    .table-head-add-icon-box .table-head-add-icon{
     color: #1976d2;
     cursor: pointer;
    }

   


    .prof-main-modal .packages-modal-main{
        width: 450px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #fff;
        border-radius: 15px;
        outline: none;
        padding: 30px 55px; 
        max-height: 480px;
        overflow: auto;   
       }
       .prof-main-modal .packages-modal-main::-webkit-scrollbar {
        display: none;
      }

.packages-modal-main .package-name-input{
    margin-top: 20px;
}

.packages-modal-main .package-name-input{
    margin-top: 20px;
    width: 325px;
}
.packages-modal-main .package-name-description{
    margin-top: 20px;
    width: 325px;

}
 .portfolio-main-box{
    padding: 20px 0px 0px 30px;
    display: flex;
    height: 280px;
}

.prof-img-container::-webkit-scrollbar {
    display: none;
  }

.packages-modal-main .service-category-input{
    margin-top: 20px;
    width: 325px;
}



.portfolio-icon-box .portfolio-icon-edit{
    color: rgb(111, 111, 170);
    cursor: pointer;  
    height: 18px;
   float: inline-end;
   margin: 8px;
}

.portfolio-box .portfolio-icon-box{
    /* padding: 30px 30px 0px 30px; */
    padding: 8px;
}



.portfolio-main-box .portfolio-img{
    width: 350px;
    height: 230px;
    border-radius: 20px;
}


.prof-main-modal .portfolio-main-container{
    width: 850px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border-radius: 15px;
    outline: none;
    padding: 30px;

    min-height: 280px;
    overflow: auto;   
   }

   
.portfolio-img-modal-box .portfolio-img-modal{
    /* width: 100%; */
    width: 220px;
    height: 140px;
    margin-right: 10px;
    border-radius: 10px;
    overflow: auto;
}

.prof-img-container-modal{
    min-height: 160px;
    margin-top: 10px;
}

.portfolio-img-modal-box {
    position: relative;
}

.portfolio-img-modal-box .portflio-img-remove-icon{
    position: absolute;
    cursor: pointer;
    top: -6%;
    right: 5%;
    background-color: #db2626;
    width: 20px;
    height: 20px;
    border-radius: 50px;
    color: #fff;
    padding: 3px;
}

.portfolio-image-select-btn .choose-file-btn{
    width: 105px;
    height: 30px;
    border-radius: 10px;
    color: #ffff;
    background: gray;
    text-transform: capitalize;
    float: inline-end;

}

.portfolio-image-select-btn .choose-file-btn:hover{
    width: 105px;
    height: 30px;
    border-radius: 10px;
    color: #ffff;
    background: #3c3c55;
    text-transform: capitalize;
}



.my-class{
    padding: 16px;
    max-height: calc(100vh - 260px);
    overflow-y: auto;
    overflow-x: hidden;
}

.my-class::-webkit-scrollbar {
    display: none;
  }

.portfolio-image-select-btn{
    display: flex;
    justify-content: center;
    margin-top: 10px;
    border: dashed #7d7de5;
    padding: 35px;
}


.tap-heading-prof{
    width: 250px;
    font-family: Arial, Helvetica, sans-serif;
}

 .tap-heading-box .tap-heading-prof{
    font-weight: 700 !important;
    text-transform: capitalize !important;
    color:black !important
}

.no-data-found-box{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.rating-text{
    color: #00a600;
}