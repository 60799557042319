.user-header-details {
  padding: 10px 0px;
  background-color: #f9f9f9;
}

.container {
  max-width: 1200px;
  padding: 0px 0px;
  margin: auto;
  width: 100%;
}

a,
a:hover {
  text-decoration: none;
  color: inherit;
}

.user-header-details .user-flex-header-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.user-header-details .user-flex-header-details .user-center-menu-list {
  display: flex;
}

.user-header-details
  .user-flex-header-details
  .user-center-menu-list
  .user-list-details {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
}

.user-header-details
  .user-flex-header-details
  .user-center-menu-list
  .user-list-details
  .user-listitem-details {
  max-width: max-content;
  margin: 0px 15px;
  position: relative;
  padding: 0px;
}

.user-header-details
  .user-flex-header-details
  .user-center-menu-list
  .user-list-details
  .user-listitem-details.active
  .user-redirect-listing {
  color: #551067;
  font-weight: 600;
}

.user-header-details
  .user-flex-header-details
  .user-center-menu-list
  .user-list-details
  .user-listitem-details
  .user-redirect-listing {
  padding: 10px 0px;
  display: flex;
  font-size: 15px;
  align-items: center;
  flex-direction: column;
  line-height: 22px;
  text-transform: uppercase;
  position: relative;
}

.user-header-details
  .user-flex-header-details
  .user-center-menu-list
  .user-list-details
  .user-listitem-details
  .user-redirect-listing::after {
  content: "";
  display: block;
  width: 0;
  height: 3px;
  position: absolute;
  bottom: 0;
  border-radius: 10px;
  background: #551067;
  transition: width 0.2s;
  margin: 8px 0px 0px 0px;
}

.user-header-details
  .user-flex-header-details
  .user-center-menu-list
  .user-list-details
  .user-listitem-details:hover
  .user-redirect-listing::after {
  width: 100%;
}

.user-header-details
  .user-flex-header-details
  .user-center-menu-list
  .user-list-details.active
  .user-listitem-details
  .user-redirect-listing {
  color: #551067;
  font-weight: 600;
}

.user-header-details
  .user-flex-header-details
  .user-center-menu-list
  .user-list-details.active
  .user-listitem-details::after {
  content: "";
  display: block;
  width: 100%;
  height: 3px;
  position: absolute;
  bottom: 0;
  border-radius: 10px;
  background: #551067;
  transition: width 0.2s;
  margin: 8px 0px 0px 0px;
}

.user-header-details
  .user-flex-header-details
  .user-right-account
  .my-user-accounticon
  .user-profile {
  height: 30px;
  width: 30px;
  object-fit: cover;
  border-radius: 50%;
}

.user-header-details
  .user-flex-header-details
  .user-right-account
  .user-flex-account {
  display: flex;
  gap: 10px;
  align-items: center;
}

.footer-bg-content {
  background-color: #f9f9f9;
  padding: 50px 0px 0px 0px;
}

.footer-bg-content .account-address-list .account-address-title {
  font-size: 18px;
  font-weight: 500;
  line-height: 18px;
  color: #2a3547;
}

.footer-bg-content
  .account-address-list
  .footer-list-details
  .footer-listitem-details {
  max-width: 300px;
  align-items: start;
}

.footer-bg-content .footer-list-details {
  padding: 20px 0px 0px;
}

.footer-bg-content .footer-list-details .footer-listitem-details {
  padding: 0px;
  margin-bottom: 15px;
  display: flex;
  gap: 10px;
  color: #2a3547;
}

.footer-bg-content
  .footer-list-details
  .footer-listitem-details
  .footer-link-text {
  color: #2a3547;
  font-size: 16px;
  line-height: 20px;
  transition: 0.5s all;
}

.footer-bg-content
  .footer-list-details
  .footer-listitem-details
  .footer-link-text:hover {
  color: #551067;
}

.footer-bg-content
  .footer-list-details
  .footer-listitem-details
  .footer-link-text
  .footer-social-icons {
  height: 30px;
  width: 30px;
}

.footer-bg-content
  .footer-list-details
  .footer-listitem-details
  .footer-add-icon {
  height: 20px;
  display: flex;
}

.footer-bg-content .account-address-list .social-footer-list {
  display: flex;
  padding-top: 10px;
  gap: 10px;
}

.copy-right-content {
  margin-top: 50px;
  padding: 15px 0px;
  background-color: #e4e4e4;
}

.copy-right-content .copy-title {
  font-size: 15px;
  line-height: 22px;
  text-align: center;
}

.user-header-details {
  transition: background-color 0.3s ease;
  background-color: rgb(111, 111, 170);
}

.sticky {
  position: sticky;
  top: 0;
  width: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.contact-us-common {
  padding: 50px 0px;
}

.contact-us-common .contact-us-details-right .get-contact-title {
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 30px;
  color: #2a3547;
}

.contact-us-common .contact-us-details-right .get-dercription {
  font-size: 17px;
  margin-bottom: 30px;
  color: #2a3547;
  line-height: 24px;
}

.contact-us-common .contact-details-show .contact-details-list {
  display: flex;
  gap: 10px;
  font-size: 17px;
  color: #2a3547;
  line-height: 24px;
  margin-bottom: 15px;
}

.contact-us-common .contact-details-show .contact-details-list span {
  font-weight: 700;
  min-width: 81px;
  display: block;
}

.contact-us-common .contact-form-list {
  margin-top: 50px;
}

.contact-us-common .contact-form-list .btn-contact-main {
  max-width: 170px;
}

.contact-us-common .contact-form-list .btn-contact-main .btn-primary {
  font-size: 16px;
  line-height: 16px;
  font-weight: 500;
  width: 100%;
  padding: 15px 15px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.contact-us-common .map-contact-details .iframe-contact-map {
  width: 100%;
  min-height: 708px;
  object-fit: cover;
}

.common-btn-details .btn-primary {
  /* background: #551067;  */
  color: white;
  border-radius: 7px;
  font-size: 14px;
  text-transform: capitalize;
  line-height: 18px;
  padding: 10px 20px;
}

.profile-details {
  height: 35px;
  width: 35px;
  object-fit: cover;
  border-radius: 50%;
}

.menu-admin-profile .MuiMenu-paper {
  padding: 15px;
}
.menu-admin-profile .MuiMenu-paper .user-profile-title p {
  font-size: 17px;
  line-height: 25px;
  font-weight: 600;
}

.menu-admin-profile .MuiMenu-paper .user-flex-details {
  display: flex;
  align-items: center;
  gap: 10px;
  border-bottom: 1px solid rgb(229, 234, 239);
  padding: 15px 0px;
}
.menu-admin-profile .MuiMenu-paper .user-flex-details .profile-user-drop {
  display: flex;
}

.menu-admin-profile
  .MuiMenu-paper
  .user-flex-details
  .profile-user-drop
  .profile-drrop {
  height: 80px;
  width: 80px;
  border-radius: 50%;
  object-fit: cover;
}
.menu-admin-profile
  .MuiMenu-paper
  .user-flex-details
  .profile-user-details
  .profile-user-name {
  font-size: 14px;
  line-height: 21px;
  color: rgb(42, 53, 71);
  font-weight: 600;
}
.menu-admin-profile
  .MuiMenu-paper
  .user-flex-details
  .profile-user-details
  .profile-user-designation {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: rgb(42, 53, 71);
}
.menu-admin-profile
  .MuiMenu-paper
  .user-flex-details
  .profile-user-details
  .profile-user-email {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: rgb(42, 53, 71);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  gap: 8px;
}

.menu-admin-profile
  .MuiMenu-paper
  .user-flex-details
  .profile-user-details
  .profile-user-email
  svg {
  height: 15px;
  width: 15px;
  display: flex;
  margin-top: 1px;
}

.menu-admin-profile .MuiMenu-paper .flex-user-profile {
  padding: 15px 0px;
}
.menu-admin-profile .MuiMenu-paper .flex-user-profile .flex-link-details {
  display: flex;
  align-items: center;
  gap: 15px;
  text-decoration: none;
}

  .flex-user-profile
  .flex-link-details
  .flex-user-img-details {
  width: 45px;
  height: 45px;
  background-color: #b699bd8c;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  border-radius: 7px;
}

.flex-link-details .flex-user-img-details .account-profile {
  height: 25px;
  width: 25px;
  object-fit: contain;
}
.menu-admin-profile
  .MuiMenu-paper
  .flex-user-profile
  .flex-link-details
  .profile-desc-admin
  .profile-drop-title {
  margin: 0px;
  font-size: 14px;
  line-height: 21px;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 600;
  color: black;
  width: 240px;
}

.profile-desc-admin .profile-drop-desc {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #2a3547;
  width: 240px;
}

.menu-admin-profile .MuiMenu-paper .logout-btn-profile {
  margin-top: 15px;
}
.menu-admin-profile .MuiMenu-paper .logout-btn-profile .outline-logout-btn {
  width: 100%;
  border: 1px solid #551067;
  color: #551067;
  text-transform: capitalize;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  padding: 5px 15px;
  border-radius: 7px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  text-transform: none;
  box-shadow: none;
}

.menu-admin-profile
  .MuiMenu-paper
  .logout-btn-profile
  .outline-logout-btn:hover {
  border: 1px solid #551067;
  color: white;
  background-color: #551067;
}


.cart-details-open{
  display: flex;
  gap: 20px;
}

.cart-details-open .main-btn{
 width: 120px;  
 height: 38px;
 color: #f9f9f9;
 text-decoration: none;
 text-transform: capitalize;
 background-color: #000;
}

.cart-details-open .main-btn:hover{
 background-color: #000;
}


.cart-details-open .main-btn-signin{
  width: 120px;  
 height: 38px;
 color: #000;
 text-transform: capitalize;  
  text-decoration: none;
 border: 1px solid #000;
}

.myuser-account{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  border-radius: 50%;
}

.profile-btn{
  width: 35px;
  height: 35px;
  border-radius: 50%;
  padding: 0;
}

.user-profile-icon{
  width: 35px;
  height: 35px;
}

.menu-admin-profile .MuiPaper-elevation{
top: 75px !important;
}