/* policy section css */

.policy-section{
    width: 80%;
    margin: auto;
    padding: 1rem 0;
    }
    .policy-heading{
    
    }
    .policy-heading .MuiTypography-h4 {
      font-weight: 600;
      font-family: Arial, Helvetica, sans-serif;
      margin-bottom: 14px;
      border-bottom: 1px solid #a9a4a4ad;
      padding-bottom: 12px;
        color: #FFF;
    }
    .policy-content{
      padding:0 20px
    }
    .policy-content .MuiTypography-body1 {
      font-size: 14px;
      line-height: 26px;
      text-align: justify;
      font-family: Arial, Helvetica, sans-serif;
      color: #fff;
    }
    
    .policy-main-box{
      background-color: gray;
      padding: 40px 0 80px 0px;
    }