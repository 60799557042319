

.professional-list-main-box .professional-list-header{
 /* width: 68%; */
 height: 60px;
 background-color: #f3f1f1;
 display: flex;
 justify-content:space-between;
 padding: 0px 15px;
 align-items: center;
 gap: 10px;
}

.professional-list-main-box{
    margin: 30px 50px;
}

.professional-list-detail-box{
    /* margin: 50px 0px; */
    margin-top: 20px;
}


.professional-list-detail-box .professional-box{
    height: 250px;
 border: 2px solid #b2b2b2;
   border-radius: 10px;
   /* margin-left: 25px; */
   display: flex;
   gap: 30px;
   padding: 10px;
}

.prof-img-box .prof-img{

    width: 115px;
    height: 100px;
    border-radius: 5px;
    cursor: pointer;
}

.professional-detail-box .prof-name{
    font-size: 18px;
    font-weight: 600;
    font-family: Arial, Helvetica, sans-serif;
    cursor: pointer;
}
.professional-detail-box .prof-name:hover{
    color:rgb(247, 98, 98) ;
}
.professional-detail-box  .prof-city-box{
    display: flex;
    margin-top: 5px;

}
.professional-detail-box  .prof-city-icon{
    height: 20px;
    margin-top: 2px;
    color: royalblue;
    margin-right: 5px;
}
.professional-detail-box .prof-email-box{
    display: flex;
    margin-top: 5px;

}
.prof-email-box  .prof-email-icon{
    height: 20px;
    margin-top: 2px;
    color: gray;
    margin-right: 5px;
}

.prof-contact-box  .prof-contact-icon{
    height: 20px;
    margin-top: 2px;
    color: #00a600;
    margin-right: 5px;
}

.professional-detail-box .prof-contact-box{
    display: flex;
    margin-top: 5px;

}

.prof-time-box{
    display: flex;
    margin-top: 10px;
}
.prof-time-box .prof-time-icon{
        height: 20px;
    margin-top: 2px;
    /* color: #00a600; */
    margin-right: 5px;
}

.prof-time-box .prof-time-text{
    margin-top: 2px;
    color: #00a600;
    font-size: 18px;
    line-height: 19px;
}

.prof-service-box{
display: flex;
margin-top: 10px;

}

.prof-service-box .prof-service-text-heading{
    font-size: 15x;
    font-weight: 600;
    color: darkslategray;
    font-family: Arial, Helvetica, sans-serif;
    margin-right: 5px;
}

.prof-package-box .prof-package-text-heading{
    font-size: 16px;
    font-weight: 600;
    color: darkslategray;
    font-family: Arial, Helvetica, sans-serif;
}

.prof-package-box{
    display: flex;
    margin-top: 5px;
    font-family: Arial, Helvetica, sans-serif;


}


.category-dropdown{
    width: 220px;

}

.category-dropdown .MuiInputBase-input {
    padding: 8.5px 16px ;
}

.header-list-dropdown{
    width: 380px;   
 display: flex;
 justify-content: space-between;
}




.tap-heading-box .tap-heading{
    /* width: 316px; */
    width: 350px;
    margin: 0px 35px;
}





/* profession-detail-div */

.prof-detail-main-box{
    margin: 20px 40px 0px 40px;
    /* height: 100vh; */
}

.prof-detail-main-box .prof-detail-heading-box {
    /* height: 150px; */
    border: 0.5px solid #b2b2b2;
    border-radius: 15px;
    display: flex;
    padding: 10px 20px;
    gap: 25px;
}
.prof-detail-img-box .prof-detail-heading-img{
    width: 150px;
    height: 125px;
    border-radius: 12px;

}

 .profile-detail-heading-right-box{
    /* width: 380px; */
    width: 35%;
}

.profile-detail-heading-right-box .name-text{
    font-size: 20px;
    font-weight: 600;
    font-family: Arial, Helvetica, sans-serif;

}

.prof-contact-box {
    display: flex;
    margin: 5px 0px;

}
.prof-email-box {
    display: flex;
    margin: 5px 0px;


}
 .prof-city-box{
    display: flex;
    margin: 5px 0px;

 }

 .prof-city-box .prof-detail-city-icon{
    height: 20px;
    margin-top: 2px;
    color: royalblue;
    margin-right: 5px;
}

.tap-heading{
    
    /* width: 338px; */
    font-family: Arial, Helvetica, sans-serif;
}

 .tap-heading-box .tap-heading{
    font-weight: 700 !important;
    text-transform: capitalize !important;
    color:black !important
}
.tap-heading-box .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected{
    color: #1976d2!important;
    background-color: #ededed !important;
    text-decoration:none !important;


}

.prof-service-right-box{
    margin-top: 65px !important;
}

.prof-package-box .text{
    margin-left: 5px;
    max-width: 380px;
    /*    overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap; */
        overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2; 
  -webkit-box-orient: vertical
}
.prof-time-box .text{
    margin-left: 5px;
}
.prof-service-right-box .prof-tagline-details{
    width: 395px;
}


/* service-tab */
.services-main-box{
    height: 300px;
    padding: 20px 30px;
    overflow: auto;
    }

.packages-table-container{
    height: 288px;
    /* padding: 0px 50px; */
}

.services-main-box .services-sub-box{
   display: flex;
   justify-content: space-around;
   width: 620px;
}

.services-main-box .service-heading-text{
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 5px;
}

.services-main-box .service-heading-text p{
    font-size: 15px;
    font-weight: 500;
    margin-top: 10px;
}
.services-sub-box .sevice-list-box{
    display: flex;
justify-content:flex-start ;
flex-direction: row;
font-size: 15px;
line-height: 28px;
}


.table-head .table-heading-cell{
    font-weight: 600;
    /* width: 300px; */
    width: 20%;
}

.packages-table-container .table-body{
    overflow: auto;
}

.table-head .table-head-last-row{
    font-weight: 600;
    width: 40%;

}


.portfolio-img-main-box{
    /* height: 300px; */
    /* padding: 30px 0px 0px 30px; */
    /* overflow: auto; */
    /* margin: 30px; */
    display: flex;
}
.portfolio-img-main-box .portfolio-img{
    height: 165px;
    width: 240px;
    border-radius: 10px;
    margin: 0px 0px 20px 20px;
}

.custom-table-container table tr th {
    background-color: rgb(179 179 205);
    font-size: 14px;
    font-weight: 700;
    padding: 8px 10px;
    /* / border-radius:; / */
  }
  
  .custom-table-container table tr th:nth-child(1) {
    width: 30%;
    border-right: 1px solid #000;
    border-bottom: 1px solid #000;
  }
  
  .custom-table-container table tr th:nth-child(2) {
    border-bottom: 1px solid #000;
    /* border-right: 1px solid #000; */

  }

  .custom-table-container table tr th:nth-child(3) {
    /* border-bottom: 1px solid #000; */
    width: 15%;
  }

  /* .custom-table-container table tr td:nth-child(3), */
  .custom-table-container table tr td:nth-child(2),
  .custom-table-container table tr td:nth-child(1) {
    border: 1px solid #000;
    padding: 8px 10px;
  }
  
  .custom-table-container table {
    border: 1px solid #000;
    border-radius: 8px;
overflow: auto;
  }

  .custom-table-container {
    height: 288px;
    padding: 20px ;

  }

  .prof-img-container{
    height: 288px;
    overflow: auto;
    padding: 30px;
  }

  .service-box-container{
    height: 300px;
    padding: 20px 0px 0px 120px ;
    display: flex;
    justify-content: space-around;
    width: max-content;
  }
  .service-box-container .service-text{
    font-size: 16px;
    font-weight: 600;
    font-family: Arial, Helvetica, sans-serif;
    margin-right: 40px;

  }

  .service-box-container .servies-list-detail{
    margin-left: 0px;
  }

.servies-list-detail ul li{
    list-style-type: disc;
}

.booking-btn-box .booking-button{
    background: #7878aa;
    color: #ffff;
    border-radius: 10px;
    text-transform: capitalize;
    width: 120px;
}
.booking-btn-box .booking-button:hover{
    background: #7878aa;
    color: #ffff;
    border-radius: 10px;
    text-transform: capitalize;
}