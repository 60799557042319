* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.meanClass {
  height: 100vh;
  display: flex;
}

.user-submit-button button {
  padding: 15px;
  border-radius: 10px;
  margin-top: 15px;
  margin-bottom: 15px;
  border: none;
  text-align: center;
  color: white;
  text-decoration: none;
  cursor: pointer;
  background-color: #4caf50;
  width: 100%;
  font-size: 16px;
}

.main-form {
  overflow: auto;
  margin: 0px 100px;
}

input {
  display: block;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid #ddd;
  border-radius: 5px;
}
.rigth .MuiInputBase-inputAdornedEnd {
  padding: 18.5px 14px;
  box-sizing: border-box;
  background-color: #fff;
}

.password-view input::placeholder{
  color:  rgb(31, 26, 26) !important;
  font-size: 13px;
}

.password-view.Mui-focused{
  border-color: rgba(0, 0, 0, 0.466) !important;
  outline: none !important;
}

.user-from {
  display: block;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 5px;
  text-align: left;
  font-weight: bold;
}

.common {
  height: 100vh;
  width: 50%;
}

.login-left {
  height: 100vh;
  width: 50%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-image: url("../../../assets/images/jpg/standard-image.jpg");
}

.left {
  height: 100vh;
  width: 50%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-image: url("../../../assets/images/jpg/standard-image.jpg");
}

.rigth {
  background-color: rgb(184, 184, 201);
  padding-top: 50px;
  text-align: center;
}

.from-error {
  color: red;
  text-align: left;
}

.user-forgot {
  text-align: right;
  text-decoration: none;
}

.remember {
  text-align: left;
}

.signUp {
  margin-top: 10px;
  color: white;
  text-align: center;
}

.button-for-login{
  margin-top: 10px;
  color: white;
  text-align: center;
  margin-bottom: 25px;
}

.sign-Bullon {
  color: black;
}

.user-heding {
  text-align: center;
}

.forgot-page {
  margin-top: 20%;
}

button {
  background: #4caf50;
  border: none;
  cursor: pointer;
  font-size: 17px;
}

.reset-submit {
  padding: 15px;
  border-radius: 10px;
  margin-top: 40px;
  margin-bottom: 15px;
  border: none;
  text-align: center;
  color: white;
  text-decoration: none;
  cursor: pointer;
  background-color: #4caf50;
  width: 100%;
  font-size: 16px;
}
.password-view{
  background-color: white;
  border-radius: 5px !important;
}

.gender-radio-value{
  padding-right: 194px;
}

 .user-resister-form{
  height: calc(100vh - 97px);
  overflow: auto;
  margin: 10px 0px;
}

.drop-down-select .MuiSelect-select {
  padding: 7px;
  padding-left: 15px;
  text-align: start;
  background-color: #fff;
  font-size: 14px;
  border-radius: 5px;
  border: 1px solid #ddd;
}