* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.change-password-admin-content {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 45px 24px;
  height: calc(100vh - 95px);
  overflow: auto;
}

.change-password-admin-content .change-password-card-max {
  max-width: 400px;
  margin: auto;
}

.change-password-admin-content .change-password-card-max .change-pass-card {
  background: #e2e5ed;
  border-radius: 8px;
  position: relative;
}

.change-password-admin-content
  .change-password-card-max
  .change-pass-card
  .change-pass-card-inner {
  padding: 60px 30px 30px 30px;
}

.change-password-admin-content
  .change-password-card-max
  .change-pass-card
  .change-pass-card-inner
  .lock-rounded-details {
  position: absolute;
  right: 0;
  left: 0;
  top: 40px;
}

.change-password-admin-content
  .change-password-card-max
  .change-pass-card
  .change-pass-card-inner
  .lock-rounded-details
  .lock-rounded-bg {
  background: #ffffff;
  max-width: 80px;
  width: 80px;
  height: 80px;
  margin: auto;
  padding: 10px;
  border-radius: 50%;
  border: 3px solid #551067;
  margin-top: -79px;
}

.change-password-admin-content
  .change-password-card-max
  .change-pass-card
  .change-pass-card-inner
  .admin-flex-common
  .admin-common-title {
  font-size: 20px;
  font-weight: 700;
  color: black;
  text-align: center;
  margin-bottom: 5px;
}

.change-password-admin-content
  .change-password-card-max
  .change-pass-card
  .change-pass-card-inner
  .admin-flex-common
  .admin-pass-title-desc {
  font-size: 14px;
  line-height: 19px;
  color: #495057;
  text-align: center;
  margin-bottom: 5px;
}

.change-password-admin-content
  .change-password-card-max
  .change-pass-card
  .change-pass-card-inner
  .change-password-details {
  padding-top: 20px;
}

.change-password-admin-content
  .change-password-card-max
  .change-pass-card
  .change-pass-card-inner
  .change-password-details
  .change-pass-textfield {
  width: 100%;
}

.change-password-admin-content
  .change-password-card-max
  .change-pass-card
  .change-pass-card-inner
  .change-password-details
  .change-pass-textfield
  input {
  padding: 11px 15px;
  border-radius: 8px;
  font-size: 15px;
  line-height: 19px;
}

.change-password-admin-content
  .change-password-card-max
  .change-pass-card
  .change-pass-card-inner
  .change-password-details
  .change-pass-textfield
  .outline-change-pass-details {
  background: #fff;
  border-radius: 10px;
}

.change-password-admin-content
  .change-password-card-max
  .change-pass-card
  .change-pass-card-inner
  .change-password-details
  .change-pass-textfield
  fieldset {
  border-color: #ceced5;
  border-width: 1px;
  border-radius: 8px;
}

.change-password-admin-content
  .change-password-card-max
  .change-pass-card
  .change-pass-card-inner
  .change-password-details
  .change-pass-textfield
  span {
  display: none;
}

.lock-container {
  width: 60px;
  height: 80px;
  position: absolute;
  margin: auto;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 100%;
}

.lock {
  background-color: #551067;
  width: 24px;
  height: 18px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -4px;
  margin-left: -12px;
  animation: wiggle 1.5s linear infinite alternate;
}
.lock:before,
.lock:after {
  content: "";
  position: absolute;
  left: 2px;
  animation: close 3s linear infinite alternate;
}
.lock:before {
  border-top: 3px solid #551067;
  border-left: 3px solid #551067;
  border-right: 3px solid #551067;
  border-radius: 10px 10px 0 0;
  width: 14px;
  height: 12px;
  top: -20px;
  transform-origin: 1px 0;
}
.lock:after {
  width: 3px;
  background-color: #551067;
  height: 15px;
  top: -11px;
}
.lock .keyhole {
  position: absolute;
  width: 4px;
  height: 7px;
  background-color: #fff;
  border-radius: 5px;
  top: 6px;
  left: 10px;
}

/* Keyframes for 'close' animation */
@keyframes close {
  0%,
  10% {
    transform: translate(0%, 0%);
  }
  20%,
  100% {
    transform: translate(0%, 8px);
  }
}

/* Keyframes for 'wiggle' animation */
@keyframes wiggle {
  0%,
  10%,
  20%,
  30%,
  40% {
    transform: rotate(0);
  }
  40%,
  50%,
  60%,
  70%,
  80% {
    transform: rotate(10deg);
  }
  45%,
  55%,
  65%,
  75%,
  85% {
    transform: rotate(-10deg);
  }
  85%,
  100% {
    transform: rotate(0);
  }
}

.common-input-field .common-label-details {
  margin-top: 0px;
  font-size: 14px;
  margin-bottom: 5px;
  color: black;
}

.change-password-form {
  overflow: auto;
  margin: 0px 0px;
}

.flex-gap-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.common-btn-details .btn-primary {
  background: #551067;
  color: white;
  border-radius: 7px;
  font-size: 14px;
  text-transform: capitalize;
  line-height: 18px;
  padding: 10px 25px;
}

.common-btn-details .btn-primary:hover {
  background: #551067;
  color: white;
}

.common-btn-details .btn-primary:disabled {
  color: white;
  opacity: 0.5;
}
