

.booking-table-btn-box .approved-btn{
    background: green;
    color: #ffff;
    border-radius: 8px;
    text-transform: capitalize;
    width: 70px;
    height: 26px;
    font-size: 12px;

}

.booking-table-btn-box .approved-btn:hover{
    background: green;
    color: #ffff;
    border-radius: 8px;
    text-transform: capitalize;
    width: 70px;
    height: 26px;
    font-size: 12px;

}


.booking-table-btn-box .rejected-btn{
    background: red;
    color: #ffff;
    border-radius: 8px;
    margin-left: 10px;
    text-transform: capitalize;
    width: 70px;
    height: 26px;
    font-size: 12px;

}

.booking-table-btn-box .rejected-btn:hover{
    background: red;
    color: #ffff;
    border-radius: 8px;
    margin-left: 10px;
    text-transform: capitalize;
    width: 70px;
    height: 26px;
    font-size: 12px;

}


.package-table .table-head .tableCell{
font-weight: 600;
}

.booking-list{
    height: 436px !important;
}


.rating-text{
    cursor: pointer;
}