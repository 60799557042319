.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .header .header-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 10vh;
    background-color: rgb(111, 111, 170);
  }
  
  .Sidebar .Sidebar-box {
    height: 100vh;
    width: 45vh;
    background-color: rgb(120, 120, 170);
  }
  
  .logout {
    color: #fff !important;
  }
  
  .Sidebar .Sidebar-box {
    height: 100vh;
    width: 45vh;
    background-color: rgb(120, 120, 170);
  }
  
  .layout-main-box {
    /* display: flex;
      width: 100%; */
  }
  
  /* Layout.css */
  
  .layout-main-box {
    display: flex;
    height: 100vh;
  }
  
  .layout-left-main {
    width: auto;
    color: #fff;
  }
  
  .layout-right-main {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  
  .header-box {
    display: flex;
    justify-content: start;
    align-items: center;
  }
  
  .header-box .icon-bar {
    margin-left: 10px;
    color: #fff;
    cursor: pointer;
  }
  
  .user-sidebar-deactive {
    display: none;
  }
  
  .Sidebar-box .contant-Sidebar-box {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .contant-Sidebar-box .contant-Sidebar-text {
    margin-top: 20px;
    font-size: 22px;
    font-weight: 500px;
    font-family: Arial, Helvetica, sans-serif;
  }
  
  .admin-menu-list ul {
    color: #000;
    list-style: none;
    padding: 0px 10px;
    margin-top: 10px;
  }

  
    .admin-menu-list ul li{
      /* background-color: #fff;  */
      color: #fff;
      cursor: pointer;
      list-style: none;
      padding: 10px 20px;
      margin-top: 20px;
      font-size: 16px;
      font-weight: 600;
      
    }
  
  .admin-menu-list .menu-item {
    background-color: rgb(120, 120, 170);
    color: #ffff;
    cursor: pointer;
    list-style: none;
    padding: 10px 20px;
    margin-top: 20px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 600;
  }
  