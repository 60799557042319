.policy-section .policy-accordion{
    border-radius: 12px;
    margin-bottom: 10px;

    /* sx={{borderRadius:"12px", marginBottom:"10px", borderBottomLeftRadius:"10px",borderBottomRightRadius:"10px"}} */
}

.policy-section .css-1086bdv-MuiPaper-root-MuiAccordion-root:last-of-type{
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
}

.policy-accordion .faq-question{
    font-weight: 600;
}