.packages-modal-main .faq-view-modal{
    margin-top: 20px;
}


.faq-view-modal .faq-view-quesiton{
    font-weight: 600;
    margin-bottom: 10px;
}


.faq-modal-box .add-edit-modal-input{
    margin: 20px 0px;
}

.main-content .table-container{
    height: 400px;
    overflow: scroll;
}

.faq-table-row .table-cell{
    font-weight: 600;
}